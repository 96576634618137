import React from 'react';
import './CurrencySelector.css';

const CurrencySelector = ({ currencies, selectedCurrency, onCurrencyChange }) => {
  return (
    <div className="currency-selector">
      <select
        className="form-select"
        value={selectedCurrency}
        onChange={onCurrencyChange}
      >
        {currencies.map((currency) => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CurrencySelector;
