import React from 'react';
import './ExchangeRatesTable.css';

const ExchangeRatesTable = ({ rates, selectedBank, onSelectBank, onSort, sortConfig }) => {
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
    }
    return '';
  };

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th onClick={() => onSort('Bank')}>
              Bank {getSortIndicator('Bank')}
            </th>
            <th onClick={() => onSort('Buying')}>
              Buying Rate {getSortIndicator('Buying')}
            </th>
            <th onClick={() => onSort('Selling')}>
              Selling Rate {getSortIndicator('Selling')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rates.map((rate, index) => (
            <tr
              key={index}
              className={selectedBank === rate.Bank ? 'selected-row' : ''}
              onClick={() => onSelectBank(rate)}
            >
              <td>{rate.Bank}</td>
              <td>{rate.Buying}</td>
              <td>{rate.Selling}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExchangeRatesTable;