import React, { useState, useEffect } from 'react';
import CurrencySelector from './components/CurrencySelector';
import ExchangeRatesTable from './components/ExchangeRatesTable';
import CurrencyCalculator from './components/CurrencyCalculator';
import './App.css';
import Footer from './components/Footer';

const App = () => {
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [filteredRates, setFilteredRates] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedBankObj, setSelectedBankObj] = useState({});
  const [exchangeRatesData, setExchangeRatesData] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'Bank', direction: 'asc' });

  useEffect(() => {
    fetch('/exchangeRates.json')
      .then(response => response.json())
      .then(data => {
        setExchangeRatesData(data);
        // Get available currencies from the keys of the data
        const currencies = Object.keys(data).filter(key => data[key].length > 0);
        setAvailableCurrencies(currencies);
        setSelectedCurrency(currencies[0]); // Set first currency as default
      })
      .catch(error => console.error('Error fetching exchange rates:', error));
  }, []);

  useEffect(() => {
    if (!exchangeRatesData || !selectedCurrency) return;

    // Get the rates for the selected currency directly from the data
    const rates = exchangeRatesData[selectedCurrency] || [];
    setFilteredRates(rates);
    
    // Set the first bank as selected if available
    if (rates.length > 0) {
      setSelectedBank(rates[0].Bank);
      setSelectedBankObj(rates[0]);
    }
  }, [selectedCurrency, exchangeRatesData]);

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const handleBankSelect = (bankData) => {
    setSelectedBank(bankData.Bank);
    setSelectedBankObj(bankData);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedRates = [...filteredRates].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];
      
      // Convert to numbers for Buying and Selling comparison
      if (key === 'Buying' || key === 'Selling') {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredRates(sortedRates);
  };

  return (
    <div className="container mt-4">
      <h1 className="mb-4" align="center">Exchange Rates from Birr to {selectedCurrency}</h1>
      <CurrencySelector
        currencies={availableCurrencies}
        selectedCurrency={selectedCurrency}
        onCurrencyChange={handleCurrencyChange}
      />
      <ExchangeRatesTable
        rates={filteredRates}
        selectedBank={selectedBank}
        onSelectBank={handleBankSelect}
        onSort={handleSort}
        sortConfig={sortConfig}
      />
      
      {selectedBank && (
        <CurrencyCalculator
          selectedBank={selectedBankObj}
          selectedCurrency={selectedCurrency}
          rates={filteredRates}
        />
      )}
      <Footer />
    </div>
  );
};

export default App;
