import React, { useState, useEffect } from 'react';
import './CurrencyCalculator.css';

const CurrencyCalculator = ({ selectedBank, selectedCurrency, rates }) => {
  const [amount, setAmount] = useState('');
  const [calculationType, setCalculationType] = useState('toBirr');
  const [result, setResult] = useState('');
  const [selectedBankRate, setSelectedBankRate] = useState(selectedBank);
  const [showBankSelector, setShowBankSelector] = useState(false);

  useEffect(() => {
    calculateResult(amount, calculationType, selectedBankRate);
  }, [amount, calculationType, selectedBankRate]);

  useEffect(() => {
    setSelectedBankRate(selectedBank);
  }, [selectedBank]);

  const calculateResult = (value, type, bank) => {
    if (!value || isNaN(value) || !bank) {
      setResult('');
      return;
    }

    const numAmount = parseFloat(value);
    const rate = type === 'toBirr' 
      ? parseFloat(bank.Selling) 
      : parseFloat(bank.Buying);

    if (type === 'toBirr') {
      setResult(`${numAmount} ${selectedCurrency} = ${(numAmount * rate).toFixed(2)} ETB`);
    } else {
      setResult(`${numAmount} ETB = ${(numAmount / rate).toFixed(2)} ${selectedCurrency}`);
    }
  };

  return (
    <div className="calculator-container">
      <h3>Currency Calculator</h3>
      <div className="calculator-form">
        <div className="bank-selection-toggle">
          <label className="toggle-label">
            <input
              type="checkbox"
              checked={showBankSelector}
              onChange={() => setShowBankSelector(!showBankSelector)}
            />
            Compare Other Bank Rates
          </label>
        </div>

        {showBankSelector && (
          <div className="bank-radio-group">
            {rates.map((bank) => (
              <label key={bank.Bank} className="bank-radio-label">
                <input
                  type="radio"
                  name="bankSelection"
                  value={bank.Bank}
                  checked={selectedBankRate?.Bank === bank.Bank}
                  onChange={() => setSelectedBankRate(bank)}
                />
                <span className="radio-custom"></span>
                <span className="bank-name">{bank.Bank}</span>
                <span className="bank-rates">
                  Buy: {bank.Buying} | Sell: {bank.Selling}
                </span>
              </label>
            ))}
          </div>
        )}

        <div className="calculator-inputs">
          <div className="input-group">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
              className="form-control"
            />
          </div>
          
          <div className="conversion-type-group">
            <label className="radio-label">
              <input
                type="radio"
                value="toBirr"
                checked={calculationType === 'toBirr'}
                onChange={(e) => setCalculationType(e.target.value)}
              />
              <span className="radio-custom"></span>
              {selectedCurrency} to ETB
            </label>
            <label className="radio-label">
              <input
                type="radio"
                value="fromBirr"
                checked={calculationType === 'fromBirr'}
                onChange={(e) => setCalculationType(e.target.value)}
              />
              <span className="radio-custom"></span>
              ETB to {selectedCurrency}
            </label>
          </div>
        </div>

        {result && (
          <div className="result">
            <div className="result-amount">{result}</div>
            <div className="selected-bank-info">
              Using {selectedBankRate.Bank} rates
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrencyCalculator;
