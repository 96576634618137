import React from 'react';
import '../Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="address">
            <h4>Office Address</h4>
            <p>Airport Road, Dabi Complex, 2nd Floor 001, Addis Ababa, Ethiopia</p>
          </div>
          <div className="contact">
            <h4>Contact Us</h4>
            <p>Email: <a href="mailto:info@bernos.info">info@bernos.info</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
